import React,{Fragment, Component} from 'react';
import emailjs from 'emailjs-com';

/* window.onload = function() {
	document.getElementById('contact-form').addEventListener('submit', function(event) {
		event.preventDefault();
		emailjs.init("user_dQuyzqq7CMDE7S1tZUJG1");
		emailjs.sendForm('mailgun', 'plannem_web_contact', this)
		.then(function(response) {
			console.log(response.status, response.text);
		}, function(error) {
			console.log(error); 
		});

		document.getElementById('contact-form').reset();
		document.getElementById('form-header').innerHTML = "<h3>Thanks!</h3>"
		document.getElementById('form-para').innerHTML = "<p>Get back to <br> you as soon as possible.</p>"
	});

} */

function sendForm(e) {
    e.preventDefault();

    emailjs.sendForm('mailgun', 'plannem_web_contact', e.target, 'user_dQuyzqq7CMDE7S1tZUJG1')
      .then((result) => {
			document.getElementById('contact-form').innerHTML = "<h3>Thanks!</h3><p>We'll get back to <br> you as soon as we can.</p>"
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
class FormStyle extends Component{
	render(){
		return(
			<Fragment>
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-5 content-inner chosesus-content text-white">
							<h2 className="box-title m-b15 ">Get in Touch<span className="bg-primary"></span></h2>
							<p className="font-16 op8" >Give us a call or send a message, we endeavour to answer all enquiries within a working day.</p>
							<p className="font-16 op8" ><i className="ti-email text-light p-r10"></i><a href="mailto:info@plannem.com.au" className="text-light">info@plannem.com.au</a></p>
							<p className="font-16 op8" ><i className="ti-mobile text-light p-r10"></i>0408 684 225</p>
						</div>
						<div className="col-lg-7 col-md-7 m-b30">
							<form className="inquiry-form  dzForm" id="contact-form" onSubmit={sendForm}>
								<div className="dzFormMsg"></div>
								<div id="form-header">
									<h3 className="box-title m-t0 m-b10">Send a Message<span className="bg-primary"></span></h3>
								</div>
								<div id="form-para">
									<p>Send us some details about your residential or commercial project and we'll get in touch to let you know how we can help.</p>
								</div>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-user text-primary"></i></span>
												<input name="name" type="text" required className="form-control" placeholder="Name" />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
												<input name="phone_no" type="text" required className="form-control" placeholder="Phone" />
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-email text-primary"></i></span>
												<input name="email_address" type="email" className="form-control" required placeholder="Email" />
											</div>
										</div>
									</div>
									
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
												<textarea name="form_message" rows="4" className="form-control" required placeholder="Tell us about your project or idea"></textarea>
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<button name="submit" type="submit" value="Send" className="site-button button-lg"> <span>Send</span> </button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default FormStyle;