import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}><span className="ti-home"></span> {/* <i className="fa fa-chevron-down"></i> */}</Link></li>
					<li><Link to={'/about'}>About</Link></li>
					<li><Link to={'services'}>Services</Link></li>
					<li><Link to={'/gallery'}>Gallery</Link></li>
				</ul>
			</>
		)
	}
}
export default HeaderMenu;