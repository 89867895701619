import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import HomeOwlSlider from './../Element/HomeOwlSlider';
//import HomeOwlSlider2 from './../Element/HomeOwlSlider2';
import HomeTab from './../Element/HomeTab';
import FormStyle from './../Element/FormStyle';
import ShortServices from './../Element/ShortServices';
/* import Counter from './../Element/Counter';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import PortfolioCarousel from './../Element/PortfolioCarousel';
import ImgCarouselContent from './../Element/ImgCarouselContent'; */

//Images
import pic4 from './../../images/about/pic4.jpg';
import bgimg from './../../images/background/bg1.jpg';
import bgimg3 from './../../images/background/bg1.jpg';

//import bgimg2 from './../../images/background/bg-map.jpg';

class Homepage extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content bg-white">
					{/*  Slider Banner */}
					<div className="owl-slider-banner main-slider">
						<HomeOwlSlider />
						{/*  Service   */}
						
						{/*  Service End  */}
					</div>
					{/*  Slider Banner */}
					<div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full bg-white content-inner-1 about-us">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box row" >
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Plann-em<span className="bg-primary"></span></h2><br />
                                                <h4 className="text-gray-dark">Professional Service with a Personal Touch.</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>
                                                    Plann-em provides comprehensive Town Planning, Design and Project Management services to the inner west, north-west and outer western regions of Melbourne and surrounding regional areas. 
                                                </p>
                                                <p>
                                                    This includes established residential, industrial and commercial areas of Melbourne and extensive new growth corridors all of which Plann-em has expansive experience and knowledge in.
                                                </p>
                                                <p>
                                                    Plann-em offer a complete or individual tailored Town Planning, Design and Project Management service for Multi-unit and townhouse developments, Change of Use, Industrial, Commercial development, Subdivision, and Rural Living.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-4 about-img" >
                                        <img src={pic4} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
						{/* <!-- Why Chose Us --> */}
                        <div className="section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <div className="section-head text-white text-center">
                                    <h2 className="box-title m-tb0">COMPLETE TOWN PLANNING SERVICES<span className="bg-primary"></span></h2>
                                    <p>Begins with honest consultation on the goals of the project and provides the detailed planning reports required for coucil assessment.</p>
                                </div>
                            </div>
							<HomeTab />
							<div className="choses-info text-white">
                            </div>
						</div>	
						{/* <!-- Why Chose Us End --> */}
						{/* <!-- Our Services --> */}
                        {/* <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Our Services</h4>
                                    <h2 className="box-title m-tb0">Town Planning <span className="bg-danger">and more</span></h2>
                                    <p>Find out more about how we can help with your project.</p>
                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div> */}
                        <ShortServices />
                        {/* <!-- Our Services --> */}
						{/* <!-- Our Portfolio --> */}
                        {/* <div className="section-full content-inner-1 mfp-gallery">
                            <div className="container-fluid">
                                <div className="section-head text-center">
                                    <h4 className="text-gray-dark m-b10">Our Portfolio</h4>
                                    <h2 className="box-title m-tb0">We work to innovate & are proud<br /> of what we've created<span className="bg-primary"></span></h2>
                                    <h5>YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!</h5>
                                </div>
                                    <PortfolioCarousel />
                            </div>
                        </div> */}
                        {/* <!-- Our Portfolio END --> */}
						{/* <!-- Testimonials --> */}
                        {/* <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgimg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Testimonials END --> */}
						
						{/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
						
					</div>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default Homepage;