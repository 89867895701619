import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

import Slider1 from './../../images/main-slider/slide2-wider.jpg';
import Slider2 from './../../images/main-slider/slide1.jpg';
import Slider3 from './../../images/main-slider/slide3.jpg';


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class HomeOwlSlider extends Component{
	
	render(){
		var settings = {		
			arrows: true,
			dots: true,
            slidesToShow: 1,			
            infinite: true,
			autoplay: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
					
				  }
				}
			]
        };
		return(
			
			<Slider className="owl-slider owl-carousel owl-theme owl-none" {...settings}>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider1} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary-dark">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-black font-weight-400">Town Planning <br /> Permits & Projects<br/></h2>
								<h4>Need a town planning permit?</h4>
								<p>We manage the application process with council, start to finish. </p>
								<p>Including design and development, change of use and subdivisions for residential, industrial, commercial, and more.</p>
								<p>If you have projects requiring town planning in Melbourne, we can plann-em.</p>
								<br />
								<Link to ={"/services"} className="site-button m-r10 white button-lg">Find Out More</Link>
								<Link to ={"/contact"} className="site-button outline outline-2 button-lg">Get in Touch</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider3} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary-dark">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Trusted Professional Network. <br/></h2>
								<p>Plann-em consult with a network of professionals; arborists, traffic engineers, surveyors, building and construction, architects, designers and more.</p>
								<Link to ={"#"} className="site-button m-r10 white button-lg">Find Out More</Link>
								<Link to ={"#"} className="site-button outline outline-2 button-lg">Get In Touch</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={Slider2} className="w-100" alt=""/></div>
					<div className="slide-content overlay-primary-dark">
						<div className="slide-content-box container">
							<div className="max-w600 text-white">
								<h2 className="text-white font-weight-400">Enhance Your Project Potential. </h2>
								<p>Get a preliminary consultation to determine whether there is greater potential for your project, allowing you to achieve the most from your space, time, and budget before applying for a permit.</p>
								<Link to ={"#"} className="site-button m-r10 white button-lg">Find Out More</Link>
								<Link to ={"#"} className="site-button outline outline-2 button-lg">Get In Touch</Link>
							</div>
						</div>
					</div>
				</div>
			</Slider>	
		)
		
	}
	
}

export default HomeOwlSlider;