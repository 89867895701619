import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import bgimg from './../../images/background/bg1.jpg';

function sendForm(e) {
    e.preventDefault();

    emailjs.sendForm('mailgun', 'plannem_web_contact', e.target, 'user_dQuyzqq7CMDE7S1tZUJG1')
      .then((result) => {
          console.log(result.text);
          document.getElementById('contact-form').innerHTML = "<h3>Thanks!</h3><p>We'll get back to <br> you as soon as we can.</p>"
      }, (error) => {
          console.log(error.text);
      });
  }
class Contact extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="full-section">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-white">
                                        <div className="col-lg-12 col-md-6 m-b30" >
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                        Melbourne, Victoria
													</h5>
                                                    <div className="lga-list">
                                                        <p>Wyndham, Hobsons Bay, Melton, Geelong, Maribyrnong</p>
                                                        <p>Mooney Valley, Brimbank, Macedon Ranges</p>
                                                        <p>Hume, Moreland, Darebin, Banyule, Whittlesea, Nillumbik, Mitchell Shire</p>
                                                        {/* <ul>
                                                            <li>Wyndham</li>
                                                            <li>Hobsons Bay</li>
                                                            <li>Melton</li>
                                                            <li>Geelong</li>
                                                            <li>Maribyrnong</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Mooney Valley</li>
                                                            <li>Brimbank</li>
                                                            <li>Macedon Ranges</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Hume</li>
                                                            <li>Moreland</li>
                                                            <li>Darebin</li>
                                                            <li>Banyule</li>
                                                            <li>Whittlesea</li>
                                                            <li>Nillumbik</li>
                                                            <li>Mitchell Shire</li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                    <div className="icon-content">
                                                    <h5 className="dlab-tilte m-b15 font-weight-400">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-alarm-clock"></i></span>
                                                        Office Hours
                                                    </h5>
                                                    <div className="oh-list">
                                                        <p className="m-b0"><b>Mon - Fri</b>  <br /> 9am - 5pm</p>
                                                        <p><b>Weekends</b> <br /> by Appointment Only</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <div className="email-list">
                                                        <p className="m-b0"><a href="mailto:info@plannem.com.au">info@plannem.com.au</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                        Phone
														</h5>
                                                    <div className="oh-list">
                                                        <p>0408 684 225</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                                    <form className="inquiry-form dzForm" id="contact-form" onSubmit={sendForm}>
                                        <div className="dzFormMsg"></div>
                                        <h3 className="box-title m-t0 m-b10">Send a Message<span className="bg-primary"></span></h3>
                                        <p>Send us some details about your residential or commercial project and we'll get in touch to let you know how we can help.</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="name" type="text" required className="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone_no" type="text" required className="form-control" placeholder="Phone" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email_address" type="email" className="form-control" required placeholder="Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="form_message" rows="4" className="form-control" required placeholder="Tell us about your project or idea"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Send" className="site-button button-lg"> <span>Send</span> </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- inner page banner END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Contact;