import React,{Fragment, Component} from 'react';
import { Link } from 'react-router-dom';

const iconBox = [
	{icon: <i className="flaticon-notebook" />, link:"/town-planning", title:'Town Planning', description:"A complete Town Planning service designed to work with you during the planning and permit approval process." },
	{icon: <i className="flaticon-process" />, link:"/project-services", title:'Project Services', description:"Planning and Project Management services to guide teams throughout council assessment & approval." },
	{icon: <i className="flaticon-sketch" />, link:"/technical-design-services", title:'Technical Drawing & Design', description:"Architectural, engineering plans, and 3D rendered drawings produced in-house and by trusted partners." },
];


class AllServices extends Component{
	render(){
		return(
			<Fragment>
                
				<div className="section-full content-inner">
                    <div className="container">
                        <div className="section-head text-black text-center">
                            <h4 className="text-gray-dark m-b10">Plann-em</h4>
                            <h2 className="box-title m-tb0">More Services<span className="bg-primary"></span></h2>
                            <p>No matter the stage of your project, <b>Plann-em can help</b> define your goals, create the reports, plans and supportive documentation for submission to council and help your project reach its full potential.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row ">
                            {iconBox.map((data, index)=>(
                                <div className="col-md-4 col-sm-6 m-b30 "  key={index}>
                                    <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                        <div className="icon-lg m-b20"> <Link to={data.link} className="icon-cell">{data.icon}</Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase"><Link to={data.link}>{data.title}</Link></h5>
                                            <p>{data.description}</p>
                                            
                                            <Link to={data.link} className="site-button radius-xl outline red">Find Out More {/*  <i className="fa fa-arrow-right"></i> */}</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}	
                        </div>
                    </div>
                </div>
			</Fragment>
		)
	}
}
export default AllServices;