import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Layout/Header1';
import Footer from '../../Layout/Footer1';
import PageTitle from '../../Layout/PageTitle';
import FormStyle from '../../Element/FormStyle';
/* import TestimonialCarousel from './../../Element/TestimonialCarousel'; */
import SectionCounter from './../../Element/SectionCounter';

//Images
import bnr5 from './../../../images/banner/bnr2.jpg';
import bg1 from './../../../images/background/bg1.jpg';
//import bgmap from './../../../images/background/bg-map.jpg';

const iconBox = [
	{icon: <i className="flaticon-notebook" />, link:"/town-planning", title:'Town Planning', description:"A complete Town Planning service designed to work with you during the planning, design and permit process." },
	{icon: <i className="flaticon-process" />, link:"/project-services", title:'Project Services', description:"Planning and Project Management services to guide teams throughout council assessment and decision." },
	{icon: <i className="flaticon-sketch" />, link:"/technical-design-services", title:'Technical Drawing & Design', description:"Architectural, engineering plans, and 3D rendered drawings produced in-house and by trusted partners." },
];

class Services extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr5 + ")" }}>
						<PageTitle motherMenu='Services'  activeMenu='Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Plann-em</h4>
                                    <h2 className="box-title m-tb0">Services<span className="bg-primary"></span></h2>
                                    <p>No matter the stage of your project, <b>Plann-em can help</b> define your goals, create the reports and supportive documentation for council approval, and help you stay in control of your residential &amp; commercial developments.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
									{iconBox.map((data, index)=>(
										<div className="col-md-4 col-sm-6 m-b30 "  key={index}>
											<div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
												<div className="icon-lg m-b20"> <Link to={data.link} className="icon-cell">{data.icon}</Link> </div>
												<div className="icon-content">
													<h5 className="dlab-tilte text-uppercase"><Link to={data.link}>{data.title}</Link></h5>
													<p>{data.description}</p>
                                                    <Link to={data.link} className="site-button radius-xl outline red">Find Out More {/*  <i className="fa fa-arrow-right"></i> */}</Link>
												</div>
											</div>
										</div>
									))}	
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Why Chose Us End --> */}
                       
                        {/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Services;