import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from '../../Layout/Header1';
import Footer from '../../Layout/Footer1';
import PageTitle from '../../Layout/PageTitle';
import FormStyle from '../../Element/FormStyle';
import SectionCounter from '../../Element/SectionCounter';

//Images
//import ShortTeam from '../../Element/Team';
//import about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
import bg1 from './../../../images/background/bg1.jpg';

import epMono from './../../../images/about/ep-monogram.jpg';
import dpMono from './../../../images/about/dp-monogram.jpg';

import bnr from './../../../images/banner/bnr2.jpg';

class About extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
                    <PageTitle motherMenu='About Plann-em' activeMenu='About' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="box-title m-tb0">Who is Plann-em<span className="bg-primary"></span></h2>
                                    
                                    <p>Plann-em is a family operated business that originated at the kitchen table of a one-bedroom unit in 2010. </p>
                                    <p>Just like our own family, Plann-em has grown and evolved since conception. With skills, knowledge and experience in delivering quality town planning and design outcomes, Plann-em continues to grow from strength to strength. Specialising in projects in Melbourne’s west, Plann-em is committed to providing quality outcomes in collaboration with all stakeholders to maximise the opportunities for each project.</p>

                                </div>
                            </div>
                            <div className="container">
                                <hr />
                                <div className="row">
                                    <div className="col-lg-10 col-md-8">
                                        <div className="left row m-lr0 ">
                                            <div className="col-lg-2 col-md-4 about-img">
                                                <img src={epMono} data-tilt alt="" />
                                            </div>
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Emma Prespanoski<span className="bg-primary"></span></h2>
                                                <p className="text-gray-dark">Principal</p>
                                            </div>
                                            <div className="col-lg-6">
                                            <p className="bio-text">Emma is the head of Town Planning and project management services of Plann-em. Passionate about design from an early age, Emma has been working as a town planner since the beginning of 2008. Emma graduated with honours in 2009 obtaining her Bachelor of Applied Science (Planning). Emma has vast experience in both the public and private sectors of town planning and enjoys the challenges and opportunities that both sides of the fence offer. Emma’s is self-motivated with a strong work ethic that is powered by determination to succeed and achieve positive results for clients.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-10 col-md-8">
                                        <div className=" left row m-lr0 ">
                                            <div className="col-lg-2 col-md-4 about-img">
                                                <img src={dpMono} data-tilt alt="" />
                                            </div>
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Dragi Prespanoski<span className="bg-primary"></span></h2>
                                                <p className="text-gray-dark">Design and Engineering Principal</p>
                                            </div>
                                            <div className="col-lg-6">
                                            <p className="bio-text">Heading up the Design services of Plann-em is Dragi who has a deep rooted passion for engineering and design. Commencing his professional career as a design draftsman with a private engineering consultancy in 2002, Dragi has developed his skills and knowledge obtaining his Bachelor of Engineering in 2012 and becoming a Charted Professional engineer in 2016. Since then Dragi has worked in engineering and management field further developing his skills and obtaining a Masters of Business Administration in 2020. Dragi has a wealth of experience from residential design to large scale industrial and commercial projects. Dragi has strong drive and determination to achieve highest results for our clients.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                       {/*  <ShortTeam /> */}
						{ /* Counetr section */ }
							<SectionCounter />
						{ /* Counetr section End*/ }
							{/* <VisionBlog /> */}
							
                        {/* <!-- Testimonials --> */}
                       {/*  <div className="section-full content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    {/* <TestimonialCarousel /> }
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- Testimonials END --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2>
						<p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mision<span className="bg-primary"></span></h3>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
								<p className="font-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 	make a type specimen book. 
								</p>
								<p className="font-16">	
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
								</p>
								<p className="font-16">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the	 industry's standard dummy text ever since the.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default About;