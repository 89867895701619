import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import About from './Pages/Aboutus/About';
/* import Error403 from './Pages/Error/Error403';
import Error404 from './Pages/Error/Error404';
import Error405 from './Pages/Error/Error405'; */
import ProjectManagement from './Pages/ProjectManagement';
import Gallery from './Pages/Gallery';

import Contact from './Pages/Contact';
import Services from './Pages/Service/Services';
import PermitApplications from './Pages/Service/PermitApplications';
import TechnicalDesign from './Pages/Service/TechnicalDesign';
import TownPlanning from './Pages/Service/TownPlanning';
import ProjectServices from './Pages/Service/ProjectServices';

import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/about' exact component={About} />
                       {/*  <Route path='/error-403' exact component={Error403} />
                        <Route path='/error-404' exact component={Error404} />
                        <Route path='/error-405' exact component={Error405} /> */}
                        <Route path='/project-management' exact component={ProjectManagement} />
                        <Route path='/gallery' exact component={Gallery} />
                        <Route path='/contact' exact component={Contact} />
                        <Route path='/services' exact component={Services} />
                        <Route path='/permit-applications' exact component={PermitApplications} />
                        <Route path='/town-planning' exact component={TownPlanning} />
                        <Route path='/project-services' exact component={ProjectServices} />
                        <Route path='/technical-design-services' exact component={TechnicalDesign} />
					</Switch>
                </div>
				<ScrollToTop />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;