import React,{Fragment, Component} from 'react';
import bg1 from './../../images/background/bg1.jpg';

/* const iconBlog =[
	{icon: <i className="flaticon-pen" />,	   title: 'Town Planning',},
	{icon: <i className="flaticon-diamond" />, title: 'Project Management',},
	{icon: <i className="flaticon-devices" />, title: 'Technical Services',},
]; */

class SectionCounter extends Component{
	render(){
		return(
			<Fragment>
				<div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
					<div className="container">
						<div className="section-head text-white text-center">
							<h2 className="box-title m-tb0 max-w800 m-auto">Professional Services with a Personal Touch<span className="bg-primary"></span></h2>
							<p>Plann-em provides comprehensive urban development services in town planning, design, and technical design while consulting with specialists across Melbourne to ensure your project is in the best possible hands.</p> 

							<p><b>Choose a qualified and experienced planning consultant for your next project.</b></p>
						</div>
					</div>
					{/* <div className="container">
						<div className="row text-white">
							{iconBlog.map((item, index)=> (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										<div className="icon-lg text-white m-b20">{item.icon} </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
											<p></p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div> */}
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>	
			</Fragment>
		)
	}
}
export default SectionCounter;