import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Header from '../../Layout/Header1';
import Footer from '../../Layout/Footer1';
import PageTitle from '../../Layout/PageTitleThreeTier';
import AllServices from '../../Element/AllServices';
import FormStyle from '../../Element/FormStyle';

//Images
import SectionCounter from './../../Element/SectionCounter';
import bnr1 from './../../../images/banner/bnr2.jpg';
//import townPlanningPic from './../../../images/services/town-planning.jpg';
/* import bgmap from './../../../images/background/bg-map.jpg'; */
import bgimg3 from './../../../images/background/bg1.jpg';
/* import about from './../../../images/about/pic3.jpg'; */

import projectPlanningPic from '../../../images/services/project-plan.jpg'; 

class ProjectServices extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Services' submenu="Services" activeMenu='Project Planning Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner exp-services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-head text-black text-center">
                                            <h4 className="text-gray-dark font-weight-500 m-b10">
                                                <hr />
                                            </h4>
                                            <h2 className="box-title m-tb0">Project Planning Services<span className="bg-primary"></span></h2>
                                                <hr />
                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                            {/* Create new row here and lay out desktop view to 9/3 or 8/4 */}
                                            <i><p className="font-weight-500 font-18">Planning management and project services for projects of all sizes. </p></i>
                                                <hr />
                                               <p className="font-weight-800 text-center font-14"><i><b>Plann-em Project Services are the glue that hold it all together <br /> bringing together the many and varied professional and trade services required to complete permit application plans for development projects, and supporting stakeholders throughout the project.</b></i></p>
                                               <hr />
                                                <ul className="service-list">
                                                    <lh className="ul-header">Project Planning Management</lh>
                                                    <li>Pre-project estimates</li> 
                                                    <li>Collaboration and Coordination</li>
                                                    <li>Planning Project Delivery</li> 
                                                </ul>
                                                <hr />
                                               <p><b>It starts by listening</b> to you and developing an understanding of the goals of the project and measuring them against the requirements of your local council.</p>
                                               <p><b>Then a design</b> in the form of a response prepared to match the brief, fit to the applicable planning scheme requirements.</p>
                                               <p><b>Finally, a submission is made</b> where we prepare the town planner's report with supportive documents and manage the process through to approval.</p>
                                        {/* <div className="row serv">
											{iconWraper.map((data,index)=>(	
												<div className="col-lg-2 col-md-4 col-sm-6 m-b30" key={index}>
													<div className="icon-bx-wraper expertise  bx-style-1 p-lr10 p-tb20 center">
														<div className="icon-bx-sm bg-primary radius m-b20">
															<Link to={"#"} className="icon-cell">{data.icon}</Link>
														</div>
														<div className="icon-content">
															<h5 className="dlab-tilte"><Link to={"#"}>{data.title}</Link></h5>
														</div>
													</div>
												</div>
											))}
                                        </div> */}
                                    </div>
                                    <div className="col-lg-6">
                                        <img src={projectPlanningPic} alt="" srcset="" />


                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- About Us --> */}
                       {/*  <div className="section-full">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={about} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">How We Work<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">Since our foundation in 2005 our goal has been to use digital technology to create experiences.</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Contrary to popular belief, Lorem Ipsum is not simply</span></li>
                                                            <li><span>Random text. It has roots in a piece of classical Latin literature</span></li>
                                                            <li><span>Latin professor at Hampden-Sydney College in Virginia</span></li>
                                                        </ul>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p>
                                                        <Link to={"#"} className="site-button radius-xl outline white">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- About Us End --> */}
                        {/* <!-- About Us --> */}
                            <AllServices />
                        {/* <!-- Our Services --> */}
                            <SectionCounter />
                        
                        {/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ProjectServices;