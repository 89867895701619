import React,{Fragment, Component} from 'react';
import { Link } from 'react-router-dom';

class ShortServices extends Component{
	render(){
		return(
			<Fragment>
				<div className="section-full content-inner">
                    <div className="container">
                        <div className="section-head text-black text-center">
                            <h2 className="box-title m-tb0">Permit Approvals Do Not Have to be Difficult<span className="bg-primary"></span></h2>
                            <h4 className="text-gray-dark m-b10">Use a Qualified Town Planning Consultant</h4>
                            <p>No matter the stage of your project, <b>Plann-em can help</b> define your goals, create the reports, plans and supportive documentation for submission to council and help your project reach its full potential.</p>
                            <Link to={'services'} className="short-service-btn site-button outline outline-2"><span className="font-weight-500">Find Out More</span></Link>
                        </div>
                    </div>
                </div>
			</Fragment>
		)
	}
}
export default ShortServices;