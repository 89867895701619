import React, {Component,useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import PageTitle from './../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import bnr2 from './../../images/banner/bnr2.jpg';

//images
import box1 from './../../images/gallery/pic1.jpg';
import box2 from './../../images/gallery/pic2.jpg';
import box3 from './../../images/gallery/pic3.jpg';
import box4 from './../../images/gallery/pic4.jpg';
import box5 from './../../images/gallery/pic5.jpg';
import box6 from './../../images/gallery/pic6.jpg';
import box7 from './../../images/gallery/pic7.jpg';
import box8 from './../../images/gallery/pic8.jpg';
import box9 from './../../images/gallery/pic9.jpg';
import box10 from './../../images/gallery/pic10.jpg';
import box11 from './../../images/gallery/pic11.jpg';

const imageBlog = [
	{  Large: box1 , tag: ['Urban',	 '3D Model',] , },
	{  Large: box2 , tag: ['Urban', '3D Model', 'Multi Purpose'] , }, 
	{  Large: box3 , tag: ['Urban', '3D Model', ] , }, 
	{  Large: box4 , tag: ['Urban', ], },
	{  Large: box5 , tag: ['Urban', ], }, 
	{  Large: box6 , tag: ['Urban' ],  }, 
	{  Large: box7 , tag: ['Urban', 	] , }, 
	{  Large: box8 , tag: ['Urban',  'Regional Living',] , }, 
	{  Large: box9 , tag: ['Urban',  'Regional Living',] , }, 
	{  Large: box10 , tag: ['Urban',  'Regional Living',] , }, 
	{  Large: box11 , tag: ['Urban',  'Regional Living',] , }, 
	
]

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
    
    //const headerBar = document.querySelector(".main-bar");
    //console.log(headerBar);

	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
      <p>View Image</p>
    </Link>
  )
}

// SRL Options

const options = {
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false
    },
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

/* const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry" ><span>{name} {''}</span> </Link>
		</li> 
	);
}; */


class Portfolio2 extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
					<PageTitle motherMenu="Gallery"  activeMenu="Gallery"  />
				</div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>	
			<Footer />
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	// eslint-disable-next-line
	const [tag, setTag] = useState('Show All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'Show All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
		<>
			<div className="section-full content-inner portfolio-section mfp-gallery">		
				<div className="container-fluid">
					<div className="section-head text-black text-center">
						<h4 className="text-gray-dark font-weight-300 m-b10">Plann-em</h4>
						<h2 className="box-title m-tb0">Image Gallery<span className="bg-primary"></span></h2>
						<p>
							A collection of 3D computer rendered images and designs from completed projects.     
						</p>
					</div>	
					{/* <div className="site-filters clearfix center  m-b40">
						<ul className="filters" data-toggle="buttons">
							<TagLi name="Show All"  handlesettag={setTag} tagActive={ tag === 'Show All' ? true : false }	/>
							<TagLi name="Urban"  handlesettag={setTag} tagActive={ tag === 'Urban' ? true : false }	/>
							<TagLi name="Regional" handlesettag={setTag} tagActive={ tag === 'Regional' ? true : false }	 />
							<TagLi name="Multi Purpose"  handlesettag={setTag} tagActive={ tag === 'Multi Purpose' ? true : false }	/>
						</ul>
					</div> */}
					<SimpleReactLightbox>
							<SRLWrapper options={options}>
							<div className="">
								<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 mfp-gallery ">
									<Masonry
										className={'my-gallery-class'} // default ''
										options={masonryOptions} // default {}
										disableImagesLoaded={false} // default false
										updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
										imagesLoadedOptions={imagesLoadedOptions} // default {}
									>
										{filteredImages.map((item, index)=>(
											<li className="card-container col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b30 drupal php wordpress" key={index}>
												<div className="dlab-box portfolio-box">
													<div className="dlab-media dlab-img-effect dlab-img-overlay1"> <img src={item.Large} alt="" />
													{/* 	<div className="dlab-info-has p-a15 bg-primary">
															<Link to={"#"} className="site-button outline radius-xl white">Wordpress</Link>
															<Link to={"#"} className="site-button outline radius-xl white pull-right">Vist Site</Link>
														</div> */}
														<div className="overlay-bx">
															<div className="overlay-icon text-white p-lr10">
                                                                <Iconimage imageToOpen={index} />
                                                              {/*   <h5>View Image</h5> */}
																{/* <h5>Website Name</h5>
																<p className="m-b10">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots</p>
																<a href="https://www.google.com/" target="bank"><i className="ti-arrow-top-right icon-bx-xs"></i></a> */}
															</div>
																
														</div>
													</div>
												</div>
											</li>
										))}	
									</Masonry>	
								</ul>
							</div>
						</SRLWrapper>
					</SimpleReactLightbox>	
				</div>	
			</div>
		</>
	);
}

export default Portfolio2;
